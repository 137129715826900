let initial = {
  user: null,
  pageSelect: "",
  enterpriseEmployeeSelect: "",
  positionEmployeeSelect: "",
  sectionEmployeeSelect: "",
  employeePageList: [],
  employeePageSelect: 0,
  employeeEditId: "",
  employeeEdit: {},
  slipPageList: [],
  slipPageSelect: 0,
  hrmPageList: [],
  hrmPageSelect: 0,
  hrmEditId: "",
  hrmEmployeeId: "",
  hrmEmployeeProfile: [],
  eventEditId: "",
  useRole: [],
  role: "",
  enterpriseHrmEmployee: "",
  branchHrmEmployee: "",
};

export default (state = initial, action) => {
  const nextState = Object.assign({}, state);
  switch (action.type) {
    case "SetUser":
      console.log("SET USER REDUCER", action.payload);
      nextState.user = action.payload;
      return nextState;
    case "Logout":
      console.log("LOGOUT REDUCER");
      nextState.user = null;
      return nextState;
    case "SetPage":
      nextState.pageSelect = action.payload;
      return nextState;
    case "SetEnterpriseEmployeeSelect":
      nextState.enterpriseEmployeeSelect = action.payload;
      return nextState;
    case "SetEmployeePageList":
      nextState.employeePageList = [...action.payload];
      return nextState;
    case "SetEmployeePageNumber":
      nextState.employeePageSelect = action.payload;
      return nextState;
    case "SetEmployeeEditId":
      nextState.employeeEditId = action.payload;
      return nextState;
    case "SetEmployeeEdit":
      nextState.employeeEdit = { ...action.payload };
      return nextState;
    case "SetSlipPageList":
      nextState.slipPageList = [...action.payload];
      return nextState;
    case "SetSlipPageNumber":
      nextState.slipPageSelect = action.payload;
      return nextState;
    case "SetHrmPageList":
      nextState.hrmPageList = [...action.payload];
      return nextState;
    case "SetHrmPageNumber":
      nextState.hrmPageSelect = action.payload;
      return nextState;
    case "SetHrmEditId":
      nextState.hrmEditId = action.payload;
      return nextState;
    case "SetHrmEmployeeId":
      nextState.hrmEmployeeId = action.payload;
      return nextState;
    case "SetHrmEmployeeProfile":
      nextState.hrmEmployeeProfile = [...action.payload];
      return nextState;
    case "SetEventEditId":
      nextState.eventEditId = action.payload;
      return nextState;
    case "SetUseRole":
      nextState.useRole = action.payload;
      return nextState;
    case "SetRole":
      nextState.role = action.payload;
      return nextState;
    case "SetEnterpriseHrmEmployee":
      nextState.enterpriseHrmEmployee = action.payload;
      return nextState;
    case "SetBranchHrmEmployee":
      nextState.branchHrmEmployee = action.payload;
      return nextState;
  }
  return state;
};
