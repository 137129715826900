import { initializeApp, getApps, getApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { getDatabase, onValue } from "firebase/database";
import { ref, get, push, set, update, query, remove } from "firebase/database";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  setDoc,
  updateDoc,
  where,
  Timestamp,
  runTransaction,
} from "firebase/firestore";
import moment from "moment";
import { v4 as uuid } from "uuid";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_apiKey,
  authDomain: process.env.REACT_APP_FIREBASE_authDomain,
  databaseURL: process.env.REACT_APP_FIREBASE_databaseURL,
  projectId: process.env.REACT_APP_FIREBASE_projectId,
  storageBucket: process.env.REACT_APP_FIREBASE_storageBucket,
  messagingSenderId: process.env.REACT_APP_FIREBASE_messagingSenderId,
  appId: process.env.REACT_APP_FIREBASE_appId,
};

let firebaseApp;
if (!getApps().length) {
  firebaseApp = initializeApp(firebaseConfig);
} else {
  firebaseApp = getApp();
}

const auth = getAuth(firebaseApp);
const storage = getStorage(firebaseApp);
const database = getDatabase(firebaseApp);
const firestore = getFirestore(firebaseApp);

// Function to get data from Realtime Database
const getRealtimeData = (referencePath) => {
  return new Promise(async (resolve) => {
    const dbRef = ref(database, referencePath);
    try {
      const snapshot = await get(dbRef);
      if (snapshot.exists()) {
        resolve({ status: true, message: "Success", data: snapshot.val() });
      } else {
        resolve({ status: false, message: "No data available", data: null });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      resolve({ status: false, message: error.message, data: null });
    }
  });
};

const getRealtimeDataWithQuery = async (referencePath, queryConstraints) => {
  const dbRef = ref(database, referencePath);

  let constructedQuery;
  if (Array.isArray(queryConstraints) && queryConstraints.length > 0) {
    // Build the query using the provided constraints
    constructedQuery = query(dbRef, ...queryConstraints);
  } else {
    // Use the reference directly if no valid constraints are provided
    constructedQuery = dbRef;
  }

  try {
    const snapshot = await get(constructedQuery);
    if (snapshot.exists()) {
      return { status: true, message: "Success", data: snapshot.val() };
    } else {
      return { status: false, message: "No data available", data: null };
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return { status: false, message: error.message, data: null };
  }
};

const getRealtimeTriggerDataWithQuery = (
  referencePath,
  queryConstraints,
  callback
) => {
  const dbRef = ref(database, referencePath);

  let constructedQuery;
  if (Array.isArray(queryConstraints) && queryConstraints.length > 0) {
    // Build the query using the provided constraints
    constructedQuery = query(dbRef, ...queryConstraints);
  } else {
    // Use the reference directly if no valid constraints are provided
    constructedQuery = dbRef;
  }

  try {
    onValue(
      constructedQuery,
      (snapshot) => {
        if (snapshot.exists()) {
          callback({ status: true, message: "Success", data: snapshot.val() });
        } else {
          callback({ status: false, message: "No data available", data: null });
        }
      },
      (error) => {
        console.error("Error fetching data:", error);
        callback({ status: false, message: error.message, data: null });
      }
    );
  } catch (error) {
    console.error("Error initializing listener:", error);
    callback({ status: false, message: error.message, data: null });
  }
};

// Function to set data in Realtime Database
const setRealtimeData = (referencePath, data) => {
  return new Promise(async (resolve) => {
    const dbRef = ref(database, referencePath);
    try {
      await set(dbRef, data);
      resolve({ status: true, message: "Data set successfully", data: null });
    } catch (error) {
      resolve({ status: false, message: error.message, data: null });
    }
  });
};

// Function to push data to Realtime Database
const pushRealtimeData = (referencePath, data) => {
  return new Promise(async (resolve) => {
    const dbRef = ref(database, referencePath);
    try {
      const newRef = await push(dbRef, data);
      resolve({
        status: true,
        message: "Data pushed successfully",
        data: newRef.key,
      });
    } catch (error) {
      resolve({ status: false, message: error.message, data: null });
    }
  });
};

// Function to update data in Realtime Database
const updateRealtimeData = (referencePath, data) => {
  return new Promise(async (resolve) => {
    const dbRef = ref(database, referencePath);
    try {
      await update(dbRef, data);
      resolve({
        status: true,
        message: "Data updated successfully",
        data: null,
      });
    } catch (error) {
      console.error("Error updating data:", error);
      resolve({ status: false, message: error.message, data: null });
    }
  });
};

const removeRealtimeData = (referencePath) => {
  return new Promise(async (resolve) => {
    const dbRef = ref(database, referencePath);
    try {
      await remove(dbRef);
      resolve({
        status: true,
        message: "Data removed successfully",
        data: null,
      });
    } catch (error) {
      console.error("Error removing data:", error);
      resolve({ status: false, message: error.message, data: null });
    }
  });
};

//storage

const uploadFileToStorage = async (file, path) => {
  const fileRef = storageRef(
    storage,
    `${path}/${uuid()}_${moment().format("DD_MM_YYYY_hh:mm")}`
  );
  await uploadBytes(fileRef, file);
  const url = await getDownloadURL(fileRef);
  return url;
};

//base
export { auth, storage, database, firestore };
//auth
export { signOut };
//realtime
export {
  getRealtimeData,
  getRealtimeDataWithQuery,
  getRealtimeTriggerDataWithQuery,
  setRealtimeData,
  pushRealtimeData,
  updateRealtimeData,
  removeRealtimeData,
};
//firestore

const getFirestoreData = async (collectionPath) => {
  try {
    const collectionRef = collection(firestore, collectionPath);
    const querySnapshot = await getDocs(collectionRef);

    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      status: true,
      message: "Collection fetched successfully",
      data: documents,
    };
  } catch (error) {
    console.error("Error getting documents:", error);
    return { status: false, message: error.message, data: null };
  }
};

const getFirestoreDataWithQuery = async (
  collectionPath,
  queryField,
  queryValue
) => {
  try {
    const collectionRef = collection(firestore, collectionPath);
    const q = query(collectionRef, where(queryField, "==", queryValue));
    const querySnapshot = await getDocs(q);

    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      status: true,
      message: "Collection fetched successfully",
      data: documents,
    };
  } catch (error) {
    console.error("Error getting documents:", error);
    return { status: false, message: error.message, data: null };
  }
};

const getFirestoreDataWithMultiQuery = async (collectionPath, queries) => {
  try {
    const collectionRef = collection(firestore, collectionPath);

    // Start with the collection reference
    let q = collectionRef;

    // Apply each query condition
    queries.forEach((condition) => {
      const [field, operator, value] = condition;
      q = query(q, where(field, operator, value));
    });

    const querySnapshot = await getDocs(q);

    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return {
      status: true,
      message: "Collection fetched successfully",
      data: documents,
    };
  } catch (error) {
    console.error("Error getting documents:", error);
    return { status: false, message: error.message, data: null };
  }
};

const addFirestoreData = async (collectionPath, data) => {
  try {
    const collectionRef = collection(firestore, collectionPath);
    const docRef = await addDoc(collectionRef, data);
    return {
      status: true,
      message: "Document added successfully",
      data: docRef.id,
    };
  } catch (error) {
    console.error("Error adding document:", error);
    return { status: false, message: error.message, data: null };
  }
};
const setFirestoreData = async (documentPath, data) => {
  try {
    const docRef = doc(firestore, documentPath);
    await setDoc(docRef, data);
    return { status: true, message: "Document set successfully", data: null };
  } catch (error) {
    console.error("Error setting document:", error);
    return { status: false, message: error.message, data: null };
  }
};

const updateFirestoreData = async (documentPath, data) => {
  try {
    const docRef = doc(firestore, documentPath);
    await updateDoc(docRef, data);
    return {
      status: true,
      message: "Document updated successfully",
      data: null,
    };
  } catch (error) {
    console.error("Error updating document:", error);
    return { status: false, message: error.message, data: null };
  }
};

const updateFirestoreDataTransaction = async (documentPaths, data) => {
  try {
    // Check if documentPaths is an array or a string
    const paths = Array.isArray(documentPaths)
      ? documentPaths
      : [documentPaths];

    await runTransaction(firestore, async (transaction) => {
      // Read all documents first
      const documents = await Promise.all(
        paths.map(async (path) => {
          const docRef = doc(firestore, path);
          const docSnapshot = await transaction.get(docRef);
          return { docRef, docSnapshot };
        })
      );

      // Check if all documents exist
      for (const { docSnapshot } of documents) {
        if (!docSnapshot.exists()) {
          throw new Error(`One or more documents do not exist!`);
        }
      }

      // Update all documents
      for (const { docRef } of documents) {
        transaction.update(docRef, data);
      }
    });

    return {
      status: true,
      message: "Document(s) updated successfully",
      data: null,
    };
  } catch (error) {
    console.error("Transaction failed: ", error);
    return { status: false, message: error.message, data: null };
  }
};

const deleteFirestoreData = async (documentPath) => {
  try {
    const docRef = doc(firestore, documentPath);
    await deleteDoc(docRef);
    return {
      status: true,
      message: "Document deleted successfully",
      data: null,
    };
  } catch (error) {
    console.error("Error deleting document:", error);
    return { status: false, message: error.message, data: null };
  }
};

const currentTimestampFirestore = () => {
  return Timestamp.now();
};

const momentToUTCDateTimestamp = (momentObj) => {
  // ตั้งเวลาเป็นเที่ยงคืน UTC (00:00:00) โดยไม่สนใจเวลาเดิม
  const utcDate = momentObj.utc().startOf("day");

  // แปลงเป็น JavaScript Date object
  const dateObj = utcDate.toDate();

  // แปลง Date object เป็น Firestore Timestamp
  return Timestamp.fromDate(dateObj);
};

export {
  getFirestoreData,
  getFirestoreDataWithQuery,
  getFirestoreDataWithMultiQuery,
  addFirestoreData,
  setFirestoreData,
  updateFirestoreData,
  updateFirestoreDataTransaction,
  deleteFirestoreData,
  currentTimestampFirestore,
  momentToUTCDateTimestamp,
};
//storage
export { uploadFileToStorage };
//app
export default firebaseApp;
