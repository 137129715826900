import React, { useState, useEffect } from "react";
import { Container } from "shards-react";
import { Grid } from "@mui/material";
import { Text, TouchableOpacity, View } from "react-native-web";
import Button from "../../../component/Button/Button";
import { useNavigate } from "react-router-dom";
import { FaChevronLeft } from "react-icons/fa";
import DatePickerRange from "../../../component/CalendarV2/DatePickerRange";
import moment from "moment";
import Dropdown from "../../../component/Dropdown/Dropdown";
import {
  getBranch,
  getEnterprise,
} from "../../../function/employee/employeeAction";
import { useDialog } from "../../../component/Modal/Popup";
import TableAttendanceView from "./components/TableAttendanceView";
import {
  getFirestoreDataWithMultiQuery,
  updateFirestoreDataTransaction,
} from "../../../service/firebase";
import { Timestamp } from "firebase/firestore";
import PopupImportLeaveView from "./components/PopupImportLeaveView";

const ManageTimeAttendance = (props) => {
  const { onBack } = props;
  const navigate = useNavigate();
  const dialog = useDialog();

  //state
  const [pageControl, setPageControl] = useState({
    currentPage: 1,
    limitItemsPerPage: 20,
  });
  const [list, setList] = useState([]);
  const [enterpriseList, setEnterpriseList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [searchData, setSearchData] = useState({
    startDate: moment(),
    endDate: moment(),
    enterpriseId: "",
    branchId: "",
  });
  //state popup
  //รายการลา
  const [showPopupImportLeaveView, setShowPopupImportLeaveView] =
    useState(false);
  const [popupLeaveViewId, setPopupLeaveViewId] = useState(null);
  //แจ้งเตือน
  const [showPopupAlert, setShowPopupAlert] = useState(false);
  const [popupAlertId, setPopupAlertId] = useState(null);
  //รายการแก้ไข
  const [showPopupEdit, setShowPopupEdit] = useState(false);
  const [popupEditId, setPopupEditId] = useState(null);

  useEffect(() => {
    initEnterprise();
  }, []);

  useEffect(() => {
    if (searchData.enterpriseId) {
      const downloadBranch = async () => {
        const branchs = await getBranch(searchData.enterpriseId);
        if (branchs.status) {
          setBranchList([
            { label: "ทั้งหมด", value: "ทั้งหมด" },
            ...branchs.data,
          ]);

          setSearchData({
            ...searchData,
            branchId: "ทั้งหมด",
          });
        }
      };
      downloadBranch();
      // downloadEmployeeInBranch(); // ไม่ต้องใช้เพราะเรียกใน useEffect ข้างล่าง กรณีไม่ใช้ สาขา
    }
  }, [searchData.enterpriseId]);

  useEffect(() => {
    if (
      searchData?.startDate &&
      searchData?.endDate &&
      searchData?.enterpriseId &&
      searchData?.branchId
    ) {
      downloadData();
    }
    console.log("trigger", searchData);
  }, [searchData]);

  const initEnterprise = async () => {
    const enterprises = await getEnterprise();
    if (!enterprises.status) {
      await dialog.alert({ message: enterprises.message });
      return;
    }
    setEnterpriseList(enterprises.data);

    setSearchData({
      ...searchData,
      enterpriseId: enterprises.data[0].value,
    });
  };

  const downloadData = async () => {
    // สร้าง queries สำหรับ Firestore โดยใช้ช่วงวันที่เดียวกัน (ไม่สนใจเวลา)
    const startOfDay = moment.utc(searchData.startDate).startOf("day").toDate();
    const endOfDay = moment.utc(searchData.endDate).endOf("day").toDate();

    const queries = [
      ["enterpriseId", "==", searchData.enterpriseId],
      ["date", ">=", Timestamp.fromDate(startOfDay)],
      ["date", "<=", Timestamp.fromDate(endOfDay)],
      ["status", "==", "Y"],
    ];

    if (searchData.branchId !== "ทั้งหมด") {
      queries.push(["branchId", "==", searchData.branchId]);
    }
    dialog.loading();
    const results = await getFirestoreDataWithMultiQuery(
      `TimeAttendance`,
      queries
    );
    if (!results.status) {
      await dialog.alert({ message: results.message });
      return;
    }

    if (results.status) {
      console.log("on data", results.data);
      let lists = [];
      //กรณีไม่นำ Manhour หรือ ot มาแสดง
      results.data.forEach((doc) => {
        lists.push({
          ...doc,
          manHourString: doc.processManHour ? doc.manHourString : "00:00",
          otString: doc.processOt ? doc.otString : "00:00",
        });
      });
      setList(lists);
    }
    dialog.cancle();
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleDeleteSelectAll = async (values) => {
    const isConfirm = await dialog.confirm({
      title: `ยืนยันการลบข้อมูล`,
      message: `คุณต้องการลบข้อมูลที่เลือกทั้งหมดหรือไม่?`,
    });
    if (!isConfirm) {
      return;
    }
    dialog.loading();
    let prepare = values.map(async (d) => {
      return await prepareDelete(d);
    });

    const prepareResults = await Promise.all(prepare);
    let paths = [];
    prepareResults.forEach((doc) => {
      if (doc.status) {
        paths = [...paths, ...doc.data];
      }
    });
    const syncResults = await updateFirestoreDataTransaction(paths, {
      status: "N",
    });
    if (!syncResults.status) {
      await dialog.alert({ message: syncResults.message });
      return;
    }
    await dialog.alert({ message: "ลบข้อมูลสำเร็จ" });
    downloadData();
  };

  const prepareDelete = async (data) => {
    if (!data?.id) {
      return { status: false, message: "id not found", data: data };
    }
    const timeAttendanceId = data?.id;

    const queryLeaves = [["timeAttendanceId", "==", timeAttendanceId]];
    const getAllLeaveId = await getFirestoreDataWithMultiQuery(
      `TotalLeave`,
      queryLeaves
    );
    if (!getAllLeaveId.status) {
      return { status: false, message: getAllLeaveId.message, data: data };
    }

    let docPaths = [`TimeAttendance/${data.id}`];
    if (getAllLeaveId.data.length > 0) {
      getAllLeaveId.data.forEach((doc) => {
        docPaths.push(`TotalLeave/${doc.id}`);
      });
    }

    return { status: true, message: "success", data: docPaths };
  };

  const handleOptions = (values) => {
    const { option } = values;

    if (option === "viewLeave") {
      setShowPopupImportLeaveView(true);
      console.log("values", values);
      setPopupLeaveViewId(values.id);
    }
  };

  return (
    <View
      style={{ width: "100%", minHeight: "100vh", backgroundColor: "#EBEBEB" }}
    >
      {showPopupImportLeaveView ? (
        <PopupImportLeaveView
          refId={popupLeaveViewId}
          onClose={() => {
            setPopupLeaveViewId(null);
            setShowPopupImportLeaveView(false);
          }}
        />
      ) : null}
      <Container style={Styles.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <View style={Styles.headerContainer}>
              <TouchableOpacity
                onPress={() => {
                  handleBack();
                }}
              >
                <FaChevronLeft style={{ fontSize: 25, color: "#4652D1" }} />
              </TouchableOpacity>
              <View style={{ width: 10 }} />
              <View>
                <Text style={Styles.textTitle}>จัดการข้อมูลเวลา</Text>
                <Text style={{ color: "#666666" }}>
                  ดู/แก้ไข ข้อมูลเวลา และการสืบค้นข้อมูล
                </Text>
              </View>
            </View>
          </Grid>
        </Grid>
        <View style={Styles.contentContainer}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Text>เลือกวันที่เริ่มต้น - วันที่สิ้นสุด</Text>
              <DatePickerRange
                startDate={searchData.startDate}
                endDate={searchData.endDate}
                placeholder={"เลือกวันที่เริ่มต้น - วันที่สิ้นสุด"}
                isTop={false}
                styles={{ width: "100%", height: 40 }}
                onChange={(values) => {
                  const { startDate, endDate } = values;
                  if (startDate && endDate) {
                    setSearchData({
                      ...searchData,
                      startDate,
                      endDate,
                    });
                  }
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Text>เลือก บริษัท</Text>
              <Dropdown
                value={searchData.enterpriseId}
                valueType={"string"}
                placeholder="เลือกบริษัท"
                list={enterpriseList}
                style={{ height: 40 }}
                onChange={(values) => {
                  const { value } = values;
                  setSearchData({
                    ...searchData,
                    enterpriseId: value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Text>เลือก สาขา</Text>
              <Dropdown
                value={searchData.branchId}
                valueType={"string"}
                placeholder="เลือกสาขา"
                list={branchList}
                style={{ height: 40 }}
                onChange={(values) => {
                  const { value } = values;
                  setSearchData({
                    ...searchData,
                    branchId: value,
                  });
                }}
              />
            </Grid>
          </Grid>

          <View style={{ flex: 1, marginTop: 20 }}>
            <TableAttendanceView
              pageControl={pageControl}
              tableData={list}
              onChange={(row) => {
                const findIndex = list.findIndex((d) => {
                  return d.id === row.id;
                });
                const newList = [...list];
                newList[findIndex] = row;
                setList(newList);
              }}
              onCheck={(row) => {
                const findIndex = list.findIndex((d) => {
                  return d.id === row.id;
                });
                const newList = [...list];
                newList[findIndex] = row;
                setList(newList);
              }}
              onCheckAll={(rows) => {
                setList(rows);
              }}
              onDeleteSelectAll={(values) => {
                handleDeleteSelectAll(values);
              }}
              onSelectPage={(data) => {
                setPageControl({
                  ...pageControl,
                  ...data,
                });
              }}
              onOption={(values) => {
                handleOptions(values);
              }}
            />
          </View>
        </View>
      </Container>
    </View>
  );
};

const Styles = {
  container: {
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  headerContainer: {
    flexDirection: "row",
    alignItems: "center",
    padding: 20,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
  },
  textTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  contentContainer: {
    flex: 1,
    boxShadow: "0px 4px 16px 0px rgba(0, 0, 0, 0.08)",
    backgroundColor: "rgba(255, 255, 255, 0.7)",
    borderRadius: 24,
    padding: 20,
    margin: 0,
    marginTop: 20,
  },
};
export default ManageTimeAttendance;
