import React from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import moment from "moment";
//components
import Button from "../../../../component/Button/Button";
import PopupBackdrop from "../../../../component/Modal/PopupBackDrop";

const PopupViewLeaveDetail = (props) => {
  const { onClose } = props;

  return (
    <PopupBackdrop>
      <View style={Styles.container}>
        <Header
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
        <Text style={Styles.dateTitle}>
          วันที่ลา : {moment().format("DD/MM/YYYY")}
        </Text>
        <View style={{ flexDirection: "row" }}>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>หัวข้อ : </Text>
            <Text>{`ลาป่วย`}</Text>
          </View>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>ประเภทการลา : </Text>
            <Text>{`เต็มวัน`}</Text>
          </View>
        </View>
        <Divider />
        <Text style={Styles.subTitle}>เหตุผลในการลา</Text>
        <Text>ddddd</Text>
        <Divider />
        <Text style={Styles.subTitle}>หลักฐานประกอบการลา</Text>
        <Button
          buttonText={"ดูไฟล์"}
          color={"primary"}
          style={{ marginTop: 10, maxWidth: 150 }}
        />

        <Divider />
        <Text style={Styles.subTitle}>ผู้อนุมัติ</Text>
        <View style={{ flexDirection: "row", marginTop: 10 }}>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>หัวข้อ : </Text>
            <Text>{`ลาป่วย`}</Text>
          </View>
          <View style={Styles.rowContent}>
            <Text style={Styles.subTitle}>ประเภทการลา : </Text>
            <Text>{`เต็มวัน`}</Text>
          </View>
        </View>
      </View>
    </PopupBackdrop>
  );
};

const Header = (props) => {
  const { onClose, onEnter } = props;
  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={Styles.title}>รายละเอียดการลา</Text>
        <Button
          style={{ width: 100 }}
          buttonText={"ปิด"}
          color={"secondary"}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </View>
      <Divider />
    </View>
  );
};

export default PopupViewLeaveDetail;

const Styles = {
  container: {
    width: "90vw",
    height: "90vh",
    maxWidth: 800,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
    zIndex: 10,
  },
  content: {
    flex: 1,
    zIndex: 0,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    flex: 1,
  },
  rowContent: {
    flex: 0.5,
    flexDirection: "row",
    alignItems: "center",
  },
  dateTitle: {
    fontSize: 18,
    fontWeight: "bold",
    marginBottom: 10,
  },
  dateContainer: {
    flex: 0.5,
    flexDirection: "row",
    alignItems: "center",
    marginTop: 10,
  },
  subTitle: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 0,
  },
};
