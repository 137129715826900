import React, { useEffect, useState } from "react";
import { View, Text, TouchableOpacity } from "react-native-web";
import { Divider } from "rsuite";
import PopupBackdrop from "../../../../component/Modal/PopupBackDrop";
import Button from "../../../../component/Button/Button";
import {
  FaCalendarAlt,
  FaCalendarCheck,
  FaCalendarPlus,
  FaUserTimes,
} from "react-icons/fa";
import { HiDocumentRemove } from "react-icons/hi";
import TablePopupImportLeaveView from "./TablePopupImportLeaveView";
import {
  getFirestoreDataWithMultiQuery,
  getRealtimeData,
} from "../../../../service/firebase";
import { useDialog } from "../../../../component/Modal/Popup";
import { list } from "firebase/storage";
import PopupViewLeaveDetail from "./PopupViewLeaveDetail";

const PopupImportLeaveView = (props) => {
  const { refId } = props;
  const { onClose } = props;
  const dialog = useDialog();

  //state
  const [list, setList] = useState([]);
  const [popupViewLeaveDetail, setPopupViewLeaveDetail] = useState(false);

  useEffect(() => {
    if (refId) {
      downloadImportLeave();
    }
  }, [refId]);

  const downloadImportLeave = async () => {
    const queries = [["timeAttendanceId", "==", refId]];
    const results = await getFirestoreDataWithMultiQuery("TotalLeave", queries);
    if (!results.status) {
      dialog.alert({ message: `เกิดข้อผิดพลาด : ${results.message}` });
      return;
    }
    const getUserTransactionProfile = async (userId) => {
      return new Promise(async (resolve) => {
        const user = await getRealtimeData(`User/${userId}`);
        if (user.status) {
          resolve(user.data);
        } else {
          resolve(null);
        }
      });
    };
    const findProfileTransaction = results.data.map(async (data) => {
      const profile = await getUserTransactionProfile(data.transactionId);
      return {
        ...data,
        transactionProfile: profile,
      };
    });
    const resultsProfile = await Promise.all(findProfileTransaction);

    setList(resultsProfile);
  };

  return (
    <PopupBackdrop>
      {popupViewLeaveDetail ? (
        <PopupViewLeaveDetail
          onClose={() => {
            setPopupViewLeaveDetail(false);
          }}
        />
      ) : null}
      <View style={Styles.container}>
        <Header
          onClose={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
        <Text style={Styles.subTitle}>บันทึกการลาเพิ่ม</Text>
        <View style={Styles.menuContainer}>
          <OptionButtons title={`บันทึก\nการลา\nตามสิทธิ์`} onPress={() => {}}>
            <FaCalendarAlt style={{ fontSize: 20, color: "#4652D1" }} />
          </OptionButtons>
          <View style={Styles.spaceMenu} />
          <OptionButtons title={`บันทึก\nการลา`} onPress={() => {}}>
            <FaCalendarPlus style={{ fontSize: 20, color: "#4652D1" }} />
          </OptionButtons>
          <View style={Styles.spaceMenu} />
          <OptionButtons title={`รับรอง\nเวลา`} onPress={() => {}}>
            <FaCalendarCheck style={{ fontSize: 20, color: "#4652D1" }} />
          </OptionButtons>
          <View style={Styles.spaceMenu} />
          <OptionButtons title={`ยังไม่ส่ง\nใบลา`} onPress={() => {}}>
            <HiDocumentRemove style={{ fontSize: 20, color: "#4652D1" }} />
          </OptionButtons>
          <View style={Styles.spaceMenu} />
          <OptionButtons title={`ขาดงาน`} onPress={() => {}}>
            <FaUserTimes style={{ fontSize: 20, color: "#4652D1" }} />
          </OptionButtons>
        </View>
        <Divider />
        <Text style={Styles.subTitle}>รายการลาทั้งหมด</Text>
        <TablePopupImportLeaveView tableData={list} />
      </View>
    </PopupBackdrop>
  );
};

const Header = (props) => {
  const { onClose, onEnter, onOption } = props;
  return (
    <View style={{ width: "100%" }}>
      <View style={{ flexDirection: "row" }}>
        <Text style={Styles.title}>รายการบันทึกการลา</Text>
        <Button
          style={{ width: 100 }}
          buttonText={"บันทึก"}
          color={"primary"}
          onClick={() => {
            if (onEnter) {
              onEnter();
            }
          }}
        />
        <View style={{ width: 20 }} />
        <Button
          style={{ width: 100 }}
          buttonText={"ปิด"}
          color={"secondary"}
          onClick={() => {
            if (onClose) {
              onClose();
            }
          }}
        />
      </View>
      <Divider />
    </View>
  );
};

export default PopupImportLeaveView;

const OptionButtons = (props) => {
  const { title } = props;
  const { onPress } = props;
  return (
    <TouchableOpacity
      style={{ justifyContent: "center", alignItems: "center" }}
      onPress={() => {
        onPress && onPress();
      }}
    >
      {props.children}
      <Text
        style={{
          fontSize: 12,
          lineHeight: 12,
          color: "#000000",
          marginTop: 5,
          textAlign: "center",
        }}
      >
        {title || ""}
      </Text>
    </TouchableOpacity>
  );
};

const Styles = {
  container: {
    width: "90vw",
    height: "90vh",
    maxWidth: 1200,
    backgroundColor: "#FFFFFF",
    borderRadius: 20,
    padding: 20,
    zIndex: 10,
  },
  content: {
    flex: 1,
    zIndex: 0,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    flex: 1,
  },
  subTitle: {
    fontWeight: "bold",
  },
  menuContainer: {
    marginTop: 10,
    flexDirection: "row",
    alignItems: "flex-start",
  },
  spaceMenu: {
    width: 15,
  },
};
